import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';

// Agregar manejo de errores
window.addEventListener('error', (event) => {
  console.error('Error:', event.error);
});

window.addEventListener('unhandledrejection', (event) => {
  console.error('Promise rejection:', event.reason);
});

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>
);
